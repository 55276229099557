<template>
  <div class="main_div">
    <div class="top_div">
      <span>{{ type.title }}</span>
      <div class="btn_div">
        <el-button class="btnAdd" type="primary" size="medium" @click="showAddDialog = true">新增{{type.title }}
        </el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <ShanghaiRSNModuleTable ref="ShanghaiRSNModuleTable" :table-headers="tableHeaders" @edit="edit" :typeVal="type"></ShanghaiRSNModuleTable>
    <AddManifestRSNDialog
        :is-show="showAddDialog"
        :item="editItem"
        @refresh="refreshList"
        @close="showAddDialog=false"
        :typeVal="type"></AddManifestRSNDialog>
  </div>
</template>

<script>
import mixin from '@/mixin/manifest/sh/contacts/contactsRSN'
import AddManifestRSNDialog from '@/views/ShanghaiManifest/components/contacts/AddManifestRSNDialog'
import ShanghaiRSNModuleTable from '@/views/ShanghaiManifest/components/ShanghaiRSNModuleTable'


export default {
  mixins: [mixin],
  components:{AddManifestRSNDialog,ShanghaiRSNModuleTable},
  data() {
    return {
      type: {
        title: '一键收发通',
        code: 3
      },
      tableHeaders: this.RESETTABLE.shanghaiRSN,
    };
  },
  methods:{
    refreshList() {
      this.$refs.ShanghaiRSNModuleTable.getList();
    },
  },

};
</script>

<style lang="stylus" scoped>
.main_div {
  height: 100%;
}

.top_div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 20px;
    font-weight: bold;
  }

  .btn_div {
    display: flex;
  }
}

.el-divider--horizontal {
  margin: 1rem 0;
}

</style>
